import type { FC } from 'react';
import { useQuery } from 'react-query';
import { InlineEditableText } from '@components/InlineEditableText';
import InputSelect from '@components/InputSelect';
import { responsiveTruncateClasses } from '@ContractBuilder/contract-builder-header/classes';
import type { EntityData } from '@ContractBuilder/types';
import { filterPublishedTemplates } from '@features/create-entity-modal';
import type { UIInputValue, UISelectionType } from '@root/@types/types';
import { fetchTemplates } from '@src/queries';

import { useEditableField } from '../hooks';

interface EditableTemplateBaseFieldProps {
  entity: EntityData;
  hasOperationsDisabled: boolean;
  onSave: (id: string, cb: () => void) => Promise<void>;
}

export const EditableTemplateBaseField: FC<EditableTemplateBaseFieldProps> = ({
  entity,
  hasOperationsDisabled,
  onSave,
}) => {
  const { data = [], isLoading } = useQuery('templates', fetchTemplates);
  const options = data.filter(filterPublishedTemplates).map(({ id, name }) => ({ value: id, name }) as UISelectionType);

  const { onCancel, onChange, onClick, onUpdate, state } = useEditableField<string>({
    defaultValue: entity.sourceResourceId,
    exitEditModeAfterConfirm: false,
    name: 'sourceResourceId',
    onSave: (_name, value, onCancel) => onSave(value, onCancel),
  });

  if (isLoading) {
    return <div className="flex h-4 w-[120px] animate-pulse rounded-xl bg-slate-200" />;
  }

  return (
    <div className="flex items-center gap-1">
      <InlineEditableText
        editor={
          <InputSelect
            isFetchingOptions={isLoading}
            menuOuterClassName="min-w-[7rem]"
            name="sourceResourceId"
            onChange={(value) => onChange(value ?? '')}
            options={options}
            size="sm"
            value={state.value as UIInputValue}
          />
        }
        editMode={state.isEditing}
        disabled={hasOperationsDisabled}
        display={entity.sourceResourceDisplayName}
        onClick={onClick}
        onCancel={onCancel}
        onConfirm={onUpdate}
        tooltipContent={!hasOperationsDisabled ? 'Change base template' : undefined}
        withEditIcon={false}
        className={responsiveTruncateClasses([
          'rounded border border-transparent px-1 py-0.5 ',
          !hasOperationsDisabled ? 'hover:border-gray-300' : '',
        ])}
      />
    </div>
  );
};
