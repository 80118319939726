import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import type { CreateRenewalFormState } from '../types';

export const useCreateRenewalForm = (defaultName: string = '') => {
  const { control, handleSubmit, watch, setValue } = useForm<CreateRenewalFormState>({
    defaultValues: {
      name: defaultName,
    },
  });

  const formValues = watch();

  useEffect(() => {
    if (!formValues.baseTemplateId) {
      setValue('skipPreviouslyRemovedBlocks', false);
      setValue('skipPreviouslyAddedBlocks', false);
    }
  }, [formValues.baseTemplateId, setValue]);

  return {
    control,
    formValues,
    handleSubmit,
  };
};
