import { useEffect, useState } from 'react';

export type OnSaveFn<T = string> = (field: string, value: T, onCancel: () => void) => Promise<void>;

interface UseEditableFieldArgs<T = string> {
  defaultValue: T;
  exitEditModeAfterConfirm?: boolean;
  name: string;
  onSave: OnSaveFn<T>;
}

interface EditableFieldState<T = string> {
  value: T;
  isEditing: boolean;
  isUpdating: boolean;
}

export const useEditableField = <T = string>({
  defaultValue,
  exitEditModeAfterConfirm = true,
  onSave,
  name,
}: UseEditableFieldArgs<T>) => {
  const [state, setState] = useState<EditableFieldState<T>>({
    value: defaultValue,
    isEditing: false,
    isUpdating: false,
  });

  useEffect(() => {
    setState((prev) => ({ ...prev, value: defaultValue }));
  }, [defaultValue]);

  useEffect(() => {
    if (!state.isEditing) {
      setState((prev) => ({ ...prev, value: defaultValue }));
    }
    // eslint-disable-next-line -- We only want to reload when `isEditing` changes
  }, [state.isEditing]);

  const handleClick = () => {
    setState((prev) => ({ ...prev, isEditing: true }));
  };

  const handleCancel = () => {
    setState((prev) => ({ ...prev, isEditing: false }));
  };

  const handleChange = (value: T) => {
    setState((prev) => ({ ...prev, value }));
  };

  const handleUpdate = async () => {
    setState((prev) => ({ ...prev, isUpdating: true }));
    try {
      await onSave(name, state.value, handleCancel);
    } finally {
      setState((prev) => ({
        ...prev,
        isUpdating: false,
        isEditing: exitEditModeAfterConfirm ? false : prev.isEditing,
      }));
    }
  };

  return {
    onCancel: handleCancel,
    onChange: handleChange,
    onClick: handleClick,
    onUpdate: handleUpdate,
    state,
  };
};
