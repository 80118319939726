import type { FC } from 'react';
import { ExclamationIcon } from '@heroicons/react/solid';

export const WarningBanner: FC = () => {
  return (
    <div className="flex items-start gap-x-3 rounded-md bg-amber-50 p-4">
      <div>
        <ExclamationIcon className="h-5 w-5 text-amber-600" aria-hidden="true" />
      </div>
      <p className="text-sm font-medium leading-5 text-amber-800">
        Changing the template for this renewal contract will reset any edited content, including additional text and
        formatting changes.
      </p>
    </div>
  );
};
