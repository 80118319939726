import { Show } from '@components/Show';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import type { HeaderGroup } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

import { responsiveCellClasses } from '../helpers/columns/shared/responsive-cols-classes';

const defaultHeadingCellClassName = 'w-42 truncate px-3 py-3.5 text-left text-sm font-normal text-info-500';

const sortIconClasses = cva(['ml-2', 'flex-none', 'rounded', 'bg-info-200'], {
  variants: {
    isSorted: {
      true: 'text-info-900 group-hover:bg-info-300',
      false: 'text-info-400 opacity-30 group-hover:visible group-focus:visible',
    },
  },
});

interface TableHeaderProps<TData> {
  tableHeaderGroups: HeaderGroup<TData>[];
}

export const TableHeader = <TData,>({ tableHeaderGroups }: TableHeaderProps<TData>) => {
  return (
    <thead className="sticky top-0 z-10 bg-info-50" id="table-header">
      {tableHeaderGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <>
              <Show when={header.column.getCanSort()}>
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  scope="col"
                  className={clsx(
                    defaultHeadingCellClassName,
                    '!px-0 !py-0',
                    header.column.columnDef?.meta?.headingCellClassName,
                    responsiveCellClasses({
                      breakpoint: header.column.columnDef.meta?.showAboveBreakpoint,
                    }),
                  )}
                >
                  <a
                    href="#"
                    className={clsx(
                      'group inline-flex w-full px-3 py-3.5',
                      header.column.columnDef.meta?.headingLinkClassName,
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                    data-testid={`sort-by-${header.column.columnDef.id}`}
                  >
                    <span className="truncate">{flexRender(header.column.columnDef.header, header.getContext())}</span>
                    <span className={sortIconClasses({ isSorted: !!header.column.getIsSorted() })}>
                      <Show when={header.column.getNextSortingOrder() === 'asc'}>
                        <ChevronDownIcon className="h-5 w-5" />
                      </Show>
                      <Show when={header.column.getNextSortingOrder() === 'desc'}>
                        <ChevronUpIcon className="h-5 w-5" />
                      </Show>
                    </span>
                  </a>
                </th>
              </Show>
              <Show when={!header.column.getCanSort()}>
                <th
                  key={header.column.columnDef.id}
                  scope="col"
                  className={clsx(defaultHeadingCellClassName, header.column.columnDef?.meta?.headingCellClassName)}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              </Show>
            </>
          ))}
        </tr>
      ))}
    </thead>
  );
};
