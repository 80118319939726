import type { FC } from 'react';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import InputSelect from '@components/InputSelect';
import { SearchableInputSelect } from '@components/SearchableInputSelect';
import { useBrandingOptions } from '@features/create-entity-modal';
import type { TemplateShort, UIInputValue, WithRequired } from '@root/@types/types';
import { isEmptyValue } from '@root/helpers';
import { GENERIC_BASE_TEMPLATES } from '@root/helpers/constants';
import { fetchTemplates } from '@src/queries';

import type { StepProps } from '../../types';

interface PublishedTemplate extends Omit<WithRequired<TemplateShort, 'name'>, 'status'> {
  status: 'PUBLISHED';
}

export const filterPublishedTemplates = (item: TemplateShort): item is PublishedTemplate => {
  return !isEmptyValue(item.name) && item.status === 'PUBLISHED';
};

const isGenericBaseTemplate = (id: string) => GENERIC_BASE_TEMPLATES.some((item) => item.id === id);

export const Step2: FC<StepProps> = ({ control, formValues, setValue }) => {
  const copyFromTemplateId = formValues?.copy_from_template;

  const { brandingFieldName, isLoading: isFetchingBranding, onCopyBrandingFromResource } = useBrandingOptions();
  const { data = [], isLoading: isFetching } = useQuery(['templates'], fetchTemplates, {
    refetchOnWindowFocus: false,
  });
  const parsedOptions = data.filter(filterPublishedTemplates).map((item) => ({ name: item.name, value: item.id }));
  const [isExistingTemplateChosen, setIsExistingTemplateChosen] = useState(
    Boolean(copyFromTemplateId && !isGenericBaseTemplate(copyFromTemplateId)),
  );

  const handleCopyFromTemplateIdChange = (templateId: UIInputValue<string>) => {
    const template = data.find((item) => item.id === templateId);
    const result = onCopyBrandingFromResource(template);

    if (result) {
      setValue(brandingFieldName, result);
    }
  };

  const hasValue = !!formValues?.copy_from_template;
  const disableExistingTemplatesPicker = hasValue && !isExistingTemplateChosen;
  const disableGenericTemplatesPicker = hasValue && !disableExistingTemplatesPicker;

  return (
    <Controller
      control={control}
      render={({ field: { ref: _ref, ...props }, fieldState: { error } }) => (
        <>
          <InputSelect
            isClearable
            isDisabled={disableGenericTemplatesPicker}
            labelText="Select a generic base template"
            options={GENERIC_BASE_TEMPLATES.map(({ id, name }) => ({ value: id, name }))}
            onChange={(value) => {
              setIsExistingTemplateChosen(false);
              return props.onChange(value);
            }}
            value={isExistingTemplateChosen ? undefined : formValues?.copy_from_template}
          />

          <div className="relative flex items-center text-gray-500">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="mx-2 flex-shrink text-sm font-normal leading-5">Or</span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>

          <SearchableInputSelect
            {...props}
            errors={error?.message}
            isDisabled={disableExistingTemplatesPicker}
            isLoading={isFetching || isFetchingBranding}
            labelText="Duplicate an existing template"
            placeholder="Search templates"
            noOptionsLeftLabel="No existing templates available"
            onChange={(value) => {
              handleCopyFromTemplateIdChange(value);
              setIsExistingTemplateChosen(!!value);
              return props.onChange(value);
            }}
            options={parsedOptions}
            // Getting the value from `formValues` as opposed to `Controller` since it does a weird caching which breaks the `Clear selection` logic
            value={isExistingTemplateChosen ? formValues?.copy_from_template : undefined}
          />
        </>
      )}
      name="copy_from_template"
    />
  );
};
