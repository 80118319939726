import type { Control } from 'react-hook-form';
import type { z } from 'zod';

import type { validationSchema } from './validation/schema';

export type CreateRenewalFormState = z.infer<typeof validationSchema>;

export interface StepProps {
  control: Control<CreateRenewalFormState>;
  formValues: CreateRenewalFormState;
  isLoading: boolean;
  step: Step;
}

export enum Step {
  Base = 1,
  Config = 2,
}
