import type { FC } from 'react';
import type { ButtonProps } from '@components/Button';
import Button from '@components/Button';
import { Show, ShowFirstMatching } from '@components/Show';
import { isEmptyValue } from '@root/helpers';

import type { StepProps } from '../types';
import { Step } from '../types';

interface FormFooterProps extends StepProps {
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
}

export const FormFooter: FC<FormFooterProps> = ({ formValues, isLoading, onClose, onNext, onPrevious, step }) => {
  const isFirstStep = step === Step.Base;
  const handleClose = () => (isFirstStep ? onClose() : onPrevious());

  const buttonProps: ButtonProps = {
    className: 'h-[2.375rem] w-full',
    isDisabled: isFirstStep ? isEmptyValue(formValues.name) : isLoading,
    kind: 'primary',
    loading: isLoading,
  };

  return (
    <div className="mt-4 flex w-full justify-between gap-4">
      <Button isDisabled={isLoading} onClick={handleClose} className="h-[2.375rem] w-full">
        {isFirstStep ? 'Cancel' : 'Back'}
      </Button>
      <ShowFirstMatching>
        <Show when={isFirstStep}>
          <Button {...buttonProps} onClick={onNext} type="button">
            Next
          </Button>
        </Show>
        <Show when={true}>
          <Button {...buttonProps} type="submit">
            Create
          </Button>
        </Show>
      </ShowFirstMatching>
    </div>
  );
};
