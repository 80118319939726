import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import InputCheckbox from '@components/InputCheckbox';
import InputSelect from '@components/InputSelect';
import { Show } from '@components/Show';
import { fetchTemplates } from '@queries/fetchTemplates';
import type { TemplateShort, WithRequired } from '@root/@types/types';
import { isEmptyValue } from '@root/helpers';

import { WarningBanner } from '../components/WarningBanner';
import type { StepProps } from '../types';

interface PublishedTemplate extends Omit<WithRequired<TemplateShort, 'name'>, 'status'> {
  status: 'PUBLISHED';
}

const filterPublishedTemplates = (item: TemplateShort): item is PublishedTemplate => {
  return !isEmptyValue(item.name) && item.status === 'PUBLISHED';
};

interface Step2Props extends StepProps {
  currentBaseTemplateName?: string;
}

export const Step2: FC<Step2Props> = ({ control, currentBaseTemplateName, formValues }) => {
  const hasSelectedTemplate = !isEmptyValue(formValues.baseTemplateId);
  const { data: templates = [], isLoading: isFetchingTemplates } = useQuery(['templates'], fetchTemplates, {
    refetchOnWindowFocus: false,
  });

  const parsedTemplateOptions = templates
    .filter(filterPublishedTemplates)
    .map((item) => ({ name: item.name, value: item.id }));

  return (
    <>
      <Controller
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            {...field}
            errors={error?.message}
            isClearable
            isDisabled={field.disabled}
            isFetchingOptions={isFetchingTemplates}
            menuOuterClassName="max-w-[32rem] !min-w-0"
            labelText="Update the template?"
            options={parsedTemplateOptions}
            placeholder={currentBaseTemplateName}
            // Getting the value from `formValues` as opposed to `Controller` since it does a weird caching which breaks the `Clear selection` logic
            value={formValues.baseTemplateId}
          />
        )}
        name="baseTemplateId"
      />
      <Show when={hasSelectedTemplate}>
        <WarningBanner />
        <div className="flex w-full flex-col gap-y-2">
          <p className="text-sm font-medium leading-5 text-info-700">Additional block content options</p>
          <div className="flex flex-col divide-y divide-gray-200 rounded-md border border-gray-200">
            <Controller
              name="skipPreviouslyRemovedBlocks"
              control={control}
              render={({ field }) => (
                <InputCheckbox
                  className="p-4 text-sm"
                  isChecked={field.value}
                  onClick={() => field.onChange(!field.value)}
                  labelText="Skip previously removed blocks"
                />
              )}
            />
            <Controller
              name="skipPreviouslyAddedBlocks"
              control={control}
              render={({ field }) => (
                <InputCheckbox
                  className="p-4 text-sm"
                  isChecked={field.value}
                  onClick={() => field.onChange(!field.value)}
                  labelText="Skip previously added blocks"
                />
              )}
            />
          </div>
        </div>
      </Show>
    </>
  );
};
