import type { ResourceBlock } from '@root/@types/base';
import type {
  AnyCase,
  AuditLogChangeType,
  DataExtractionData,
  DynamoDBResourceComment,
  DynamoDBResourceSection,
  DynamoDBRootResource,
  EndorsementPremiumChange,
  EndorsementSummary,
  RootResourceStatus,
  StandardisedSectionName,
  User,
} from '@root/@types/types';

export interface LibraryBlockType extends ResourceBlock {
  class_of_business?: string;
  selected?: boolean;
  standardised_section_name: StandardisedSectionName;
  variationsCount: number;
}

export type LibraryBlockTypeForListPage = LibraryBlockType & {
  usedInTemplatesCount?: number;
  parentBlockId?: string;
  subRows?: LibraryBlockTypeForListPage[];
};

// https://docs.aws.amazon.com/guardduty/latest/ug/monitoring-malware-protection-s3-scans-gdu.html
export type MalwareScanStatus = 'NO_THREATS_FOUND' | 'THREATS_FOUND' | 'UNSUPPORTED' | 'ACCESS_DENIED' | 'FAILED';

export interface Attachment {
  id: string;
  name: string;
  fileName: string;
  size: number;
  path: string;
  type: 'pre' | 'post' | string;
  status?: 'pending' | 'ready' | string;
  malware_scan_status?: MalwareScanStatus | 'PENDING' | string;
  attachment_id?: string;
}

export interface AttachmentFE {
  id: string;
  fileName: string;
  name: string;
  size: number;
  status?: 'pending' | 'ready' | string;
  malware_scan_status?: MalwareScanStatus | string;
}

interface CommentAddedAuditLogInfo {
  isResolved?: boolean;
  threadId?: string;
  content: string;
}

export type AuditLogInfo =
  | { info: CommentAddedAuditLogInfo; type: AuditLogChangeType.COMMENT_ADDED }
  | { type: Exclude<AuditLogChangeType, AuditLogChangeType.COMMENT_ADDED> };

export type AuditLogItem = {
  created_at: string;
  block_id: string | null;
  block_name: string | null;
  changeBaseTemplateMetadata?: {
    nextSourceResourceDisplayName: string;
    prevSourceResourceDisplayName: string;
  };
  entity_id?: string;
  entity_name?: string;
  updated_data_items?: string[] | null;
  updated_status?: { from: StatusType; to: StatusType };
  user?: User;
} & AuditLogInfo;

export type RevisionHistoryItem = {
  revision_id: string;
  revision_name: string;
  revision_created_at: string;
  updated_at: string;
  last_updated_by: User;
};

export interface Section extends DynamoDBResourceSection {
  blocks: ResourceBlock[];
}

export type EntityData = Omit<DynamoDBRootResource, 'data_items' | 'status'> & {
  attachments?: Attachment[];
  audit_log: AuditLogItem[];
  comments: DynamoDBResourceComment[];
  dataExtractions?: DataExtractionData[];
  data_items: Record<string, Record<string, string | number> | any>;
  parentId?: string;
  revision_history?: RevisionHistoryItem[];
  sections: Section[];
  status?: StatusType;
};

export interface EndorsementData extends EntityData {
  additional_premium?: { amount: number; currency: string };
  effective_date?: string;
  expiry_date?: string;
  parentId: string;
  premium_change?: EndorsementPremiumChange;
  reason?: string;
  summary: EndorsementSummary;
  reference?: string;
  type?: string;
  // @TODO: [CB-2084](https://artificiallabs.atlassian.net/browse/CB-2084)
  // name?: string;
}

export const isEndorsementData = (entity?: EntityData | EndorsementData): entity is EndorsementData => {
  if (!entity) {
    return false;
  }

  return entity.pk.startsWith('submissions#') && entity.sk.startsWith('endorsements#');
};

export type SubmissionPatchResponse =
  | {
      message: 'Server Error';
    }
  | { message: 'success'; blockId?: string };

export type StatusType = RootResourceStatus;

export type PublishingOptionType = {
  id: StatusType;
  title: AnyCase<StatusType>;
  description: string;
};

export type UsedInTemplates = {
  id: string;
  name: string;
}[];
