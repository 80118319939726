import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import InputSelect from '@components/InputSelect';
import { SearchableInputSelect } from '@components/SearchableInputSelect';
import type { EntityData } from '@ContractBuilder/types';
import { useBrandingOptions } from '@features/create-entity-modal';
import type { TemplateShort, UIInputValue, WithRequired } from '@root/@types/types';
import { isEmptyValue } from '@root/helpers';
import { fetchSubmissions, fetchTemplates } from '@src/queries';

import type { StepProps } from '../../types';

interface PublishedTemplate extends Omit<WithRequired<TemplateShort, 'name'>, 'status'> {
  status: 'PUBLISHED';
}

const filterPublishedTemplates = (item: TemplateShort): item is PublishedTemplate => {
  return !isEmptyValue(item.name) && item.status === 'PUBLISHED';
};

const filterSubmissions = (item: EntityData) => {
  return !isEmptyValue(item.name);
};

export const Step2: FC<StepProps> = ({ control, formValues, setValue }) => {
  const { brandingFieldName, isLoading: isFetchingBranding, onCopyBrandingFromResource } = useBrandingOptions();
  const { data: templates = [], isLoading: isFetchingTemplates } = useQuery(['templates'], fetchTemplates, {
    refetchOnWindowFocus: false,
  });
  const { data: submissions = [], isLoading: isFetchingSubmissions } = useQuery(['submissions'], fetchSubmissions, {
    refetchOnWindowFocus: false,
  });

  const handleCopyFromEntityIdChange = (entityId: UIInputValue, mode: 'submissions' | 'templates') => {
    const data = mode === 'submissions' ? submissions : templates;
    const entity = data.find((item) => item.id === entityId);

    const result = onCopyBrandingFromResource(entity);

    if (result) {
      setValue(brandingFieldName, result);
    }
  };

  const parsedTemplates = templates
    .filter(filterPublishedTemplates)
    .map((item) => ({ name: item.name, value: item.id }));

  const submissionOptions = submissions.filter(filterSubmissions).map((item) => ({ name: item.name, value: item.id }));

  const isLoading = isFetchingSubmissions || isFetchingTemplates || isFetchingBranding;

  const disableTemplatesPicker = !!formValues?.copy_from_submission || isLoading;
  const disableSubmissionsPicker = !!formValues?.copy_from_template || isLoading;

  return (
    <div className="flex flex-col">
      <Controller
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            {...field}
            errors={error?.message}
            isClearable
            isDisabled={disableTemplatesPicker}
            isLoading={isLoading}
            isSearchable
            menuOuterClassName="max-w-[32rem] !min-w-0"
            onChange={(value) => {
              handleCopyFromEntityIdChange(value, 'templates');
              return field.onChange(value);
            }}
            labelText="Select a template"
            options={parsedTemplates}
            placeholder="Search"
            value={formValues?.copy_from_template}
          />
        )}
        name="copy_from_template"
      />

      <div className="relative flex items-center py-5 text-gray-500">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="mx-2 flex-shrink text-sm font-normal leading-5">Or</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>

      <Controller
        control={control}
        render={({ field: { ref: _ref, ...props }, fieldState: { error } }) => (
          <SearchableInputSelect
            {...props}
            errors={error?.message}
            isDisabled={disableSubmissionsPicker}
            isLoading={isLoading}
            noOptionsLeftLabel="No existing contracts available"
            onChange={(value) => {
              handleCopyFromEntityIdChange(value, 'submissions');
              return props.onChange(value);
            }}
            labelText="Duplicate an existing contract"
            placeholder="Search contracts"
            options={submissionOptions}
            // Getting the value from `formValues` as opposed to `Controller` since it does a weird caching which breaks the `Clear selection` logic
            value={formValues?.copy_from_submission}
          />
        )}
        name="copy_from_submission"
      />
    </div>
  );
};
